<template>
    <el-dialog
        :title="$t('employees.change_password')"
        :visible.sync="modalChangePassword"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <el-row>
            <el-col :span="20" :offset="2">
                <el-form
                    ref="form"
                    v-loading="$wait.is('updating_password')"
                    :model="formData"
                    label-position="top"
                    :rules="rules"
                    @submit.native.prevent
                    @keyup.native.enter="updatePassword"
                >
                    <el-form-item :label="$t('employees.password')" prop="password">
                        <el-input v-model="formData.password" type="password" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.repeat_password')" prop="password_repeat">
                        <el-input v-model="formData.password_repeat" type="password" />
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('updating_password')" @click="updatePassword">{{ $t('common.update') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalChangePassword: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            formData: {
                password:        '',
                password_repeat: '',
            },
            rules: {
                password: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { min: 5, message: this.$t('common.min_5_characters'), trigger: 'blur' },
                ],
                password_repeat: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { min: 5, message: this.$t('common.min_5_characters'), trigger: 'blur' },
                ],
            },
        };
    },

    methods: {
        updatePassword() {
            // @Filip
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.$wait.start('updating_password');
                    try {
                        await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.$route.params.uuid}/update_password`, {
                            password:        this.formData.password,
                            password_repeat: this.formData.password_repeat,
                        });
                        this.$wait.end('updating_password');
                        this.$notify.success({ title: this.$t('common.success') });
                        this.closeModal();
                    } catch (error) {
                        this.$wait.end('updating_password');
                    }
                } else {
                    return false;
                }
            });
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
